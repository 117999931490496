body {
  margin: 0;
}

#talkdesk-id-login-ui {
  height: 100%;
}

.bottom-space {
  margin-bottom: 2em;
}

.vertical-space {
  margin-top: 2em;
}

.container-max-width {
  max-width: 100%;
}

.container-width {
  width: 380px;
}
